import type { FilterType } from '@typings/search';
import { isPricingName } from '../../utils/validate';

const getFilterType = (filter: Search.FilterDetails): FilterType => {
	if (isPricingName(filter.name)) return 'starting-price';
	if (filter.isSingleSelect) return 'facet-single-select';
	return 'facet-multi-select';
};

export const addTypeToFilters = (filters: Search.FilterDetails[]) =>
	filters.map((filter) => ({
		...filter,
		type: getFilterType(filter),
	}));
