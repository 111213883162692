import { atom } from 'jotai';
import type { SemanticSearchState, SemanticSearchTypeahead } from './types';

export const semanticSearchInitialState: SemanticSearchState = {
	isFetching: false,
	isSemanticSearchCalled: false,
	response_id: '',
	searchFieldValue: '',
	storefronts: [],
	storefrontsCount: 0,
	isError: false,
};

export const semanticSearchResponseAtom = atom<SemanticSearchState>(
	semanticSearchInitialState,
);

export const resetSemanticSearchResponseAtom = atom(null, (get, set) => {
	set(semanticSearchResponseAtom, semanticSearchInitialState);
});

export const isFetchingAtom = atom(
	(get) => get(semanticSearchResponseAtom).isFetching,
);

export const isErrorAtom = atom(
	(get) => get(semanticSearchResponseAtom).isError,
);

export const updateIsFetchingAtom = atom(
	(get) => get(semanticSearchResponseAtom),
	(get, set, isFetching: boolean) => {
		const currentState = get(semanticSearchResponseAtom);
		set(semanticSearchResponseAtom, {
			...currentState,
			isFetching,
		});
	},
);

export const updateIsSemanticSearchCalled = atom(
	(get) => get(semanticSearchResponseAtom),
	(get, set, isSemanticSearchCalled: boolean) => {
		const currentState = get(semanticSearchResponseAtom);
		set(semanticSearchResponseAtom, {
			...currentState,
			isSemanticSearchCalled,
		});
	},
);

export const updateIsErrorAtom = atom(
	(get) => get(semanticSearchResponseAtom),
	(get, set, isError: boolean) => {
		const currentState = get(semanticSearchResponseAtom);
		set(semanticSearchResponseAtom, {
			...currentState,
			isError,
		});
	},
);

export const storefrontsCountAtom = atom(
	(get) => get(semanticSearchResponseAtom).storefrontsCount,
);

export const searchFieldValueAtom = atom(
	(get) => get(semanticSearchResponseAtom).searchFieldValue,
);

export const isSemanticSearchCalledAtom = atom(
	(get) => get(semanticSearchResponseAtom).isSemanticSearchCalled,
);

export const semanticSearchTypeaheadAtom = atom<SemanticSearchTypeahead>({
	semanticSearchResults: [],
	storefronts: [],
});

export const fieldValueAtom = atom('');

export const isSemanticSuggestionsOpenAtom = atom(false);
