import unleashBootstrap from '@server/feature-flags/unleash-bootstrap.json';
import type { FeatureFlags, FeatureFlagsKey } from './types';

export const FEATURE_FLAGS = {
	ELEMONOPY_TEST: 'ElemenopyTest',
	STOREFRONT_AVAILABILITY: 'tk-storefront-availability',
	STOREFRONT_SCANNABILITY: 'tk-storefront-scannability',
	LISTINGS_PRICING: 'tk-listings-pricing',
	STOREFRONT_DETAILED_PRICING: 'tk-storefront-detailed-pricing',
	UNIFIED_VENDOR_BIO: 'tk-unified-vendor-bio',
	STOREFRONT_REVIEWS_AI_SUMMARY: 'tk-storefront-reviews-ai-summary',
	STOREFRONT_REVIEWS_AI_SUMMARY_DC: 'tk-storefront-reviews-ai-summary-dc',
	STOREFRONT_COUPLES_USUALLY_SPEND: 'customers-usually-spend',
	STOREFRONT_EMPTY_STATES: 'tk-storefront-bvc-plus-empty-states',
	STOREFRONT_BOOKING_TEST_1: 'tk-storefront-booking-test1',
	STOREFRONT_BOOKING_TEST_2: 'tk-storefront-booking-test2',
	STOREFRONT_BOOK_NOW: 'tk-storefront-book-now',
} as const;

export const getInitialState = (): FeatureFlags =>
	Object.values(FEATURE_FLAGS)?.reduce(
		(
			result: Record<FeatureFlagsKey, boolean>,
			featureFlag: FeatureFlagsKey,
		) => {
			result[featureFlag] =
				unleashBootstrap.features.find((f) => f.name === featureFlag)
					?.enabled || false;
			return result;
		},
		{} as FeatureFlags,
	) || {
		ElemenopyTest: false,
		'tk-storefront-availability': false,
		'tk-storefront-scannability': false,
		'tk-listings-pricing': false,
		'tk-storefront-detailed-pricing': false,
		'tk-unified-vendor-bio': false,
		'tk-storefront-reviews-ai-summary': false,
		'tk-storefront-reviews-ai-summary-dc': false,
		'customers-usually-spend': false,
		'tk-storefront-bvc-plus-empty-states': false,
		'tk-storefront-booking-test1': false,
		'tk-storefront-booking-test2': false,
		'tk-storefront-book-now': false,
	};
