import personalizedSearchQuery from '@api/graphql/personalizedSearch';
import axios from '@axios';
import type { Experiments } from '@typings/redux';
import type { StateCodes } from '@typings/states';
import { generateSeed } from '@utils/generateSeed';
import sortParam from '@utils/sortParam';
import {
	type RangeFilter,
	categoryMap,
	rangeFiltersMap,
	vanityGuids,
} from './utils';

type SearchType = 'distance' | 'featured' | 'recommended' | 'review-rating';

type ExperimentHeaders = { [key: string]: string };

export interface SearchArgs {
	categoryGuid: string;
	city: string;
	filters?: string[];
	fixedPageSize?: boolean;
	limit: number;
	page: number;
	seed: string;
	rangeFilters?: RangeFilter[];
	sort?: SearchType;
	state: StateCodes;
	visitorId?: string;
	experiments?: Experiments;
}

export const search = async (
	args: SearchArgs,
	experimentHeaders?: ExperimentHeaders,
): Promise<API.SearchResponse> => {
	const {
		categoryGuid,
		city,
		filters,
		fixedPageSize,
		limit,
		page,
		rangeFilters,
		seed,
		sort,
		state,
	} = args;

	const shouldOnlyShowBaseFilter = filters?.some((filter) =>
		vanityGuids.includes(filter),
	);
	const rangeFiltersFromFacet: RangeFilter[] = [];

	const vanityMatch = filters?.find((filter) => vanityGuids.includes(filter));
	const catCode = categoryMap[categoryGuid];
	const rangeFilterKey = `${catCode}-${vanityMatch}`;
	const rangeFilter = rangeFiltersMap[rangeFilterKey];

	if (rangeFilter) {
		rangeFiltersFromFacet.push(rangeFilter);
	}

	const searchFilters = shouldOnlyShowBaseFilter
		? [categoryGuid]
		: filters || [categoryGuid];
	const apiPage = page > 0 ? page - 1 : 0;

	const data = {
		query: personalizedSearchQuery(),
		variables: {
			categoryGuid,
			city,
			filters: searchFilters,
			limit: limit || 30,
			page: apiPage,
			rangeFilters: rangeFiltersFromFacet.length
				? rangeFiltersFromFacet
				: rangeFilters,
			search_fallback_offset: apiPage * 30,
			seed: seed || generateSeed(),
			sort: sortParam(sort),
			state,
			fixedPageSize: fixedPageSize || false,
		},
	};

	const response = await axios.post<API.SearchResponseData>(data, {
		headers: experimentHeaders,
	});

	return response;
};

export default search;
